/* eslint-disable @typescript-eslint/ban-ts-comment */
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { Box, Button, Checkbox, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useFormik } from 'formik'
import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { useCreateUserMutation, useUpdateUserMutation } from '../../../redux/api/agentApi'
import { IUser, RoleOption } from '../../../types'

const validationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
})

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
  noMargin: {
    margin: '0px!important',
  },
}

const animatedComponents = makeAnimated()

interface Option {
  value: string | number
  label: string
}

const DEFAULT_ROLE_ITEM: RoleOption = {
  applicationId: -1,
  userRoleId: -1,
  isDefaultFlg: false,
}

interface Props {
  onClose: () => void
  refetch: () => void
  user?: IUser
  options?: Option[]
  applications?: Option[]
  open: boolean
}

export const AgentUserForm = ({
  onClose,
  refetch,
  options = [],
  applications = [],
  user,
  open,
}: Props) => {
  const [roles, setRoles] = React.useState<RoleOption[]>([DEFAULT_ROLE_ITEM])
  const [createUser, { isLoading, isError, error, isSuccess }] = useCreateUserMutation()
  const [
    updateUser,
    { isLoading: uIsLoading, isError: uIsError, error: uError, isSuccess: uIsSuccess },
  ] = useUpdateUserMutation()

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      onClose()
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  React.useEffect(() => {
    if (uIsSuccess) {
      toast.success('Success!')
      onClose()
      refetch()
    }
    if (uIsError) {
      if (Array.isArray((uError as any)?.data?.error)) {
        const errors = uError as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((uError as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [uIsLoading])

  const formik = useFormik({
    initialValues: {
      email: user?.email ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      phone: user?.phone ?? '',
      dealerGroupNumber: user?.dealerGroupNumber ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!isValid()) {
        toast.error('Please choose at least default application!')
        return
      }

      const newRoles = roles.filter(
        (role) => !(role.userRoleId === -1 && role.applicationId === -1),
      )
      if (user?.userId) {
        updateUser({
          userId: user?.userId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          dealerGroupNumber: values.dealerGroupNumber,
          newUserRoleIdList: newRoles,
        })
      } else {
        createUser({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          dealerGroupNumber: values.dealerGroupNumber,
          newUserRoleIdList: newRoles,
        })
      }

      reset()
    },
  })

  React.useEffect(() => {
    if (user) {
      formik.setValues({
        email: user?.email ?? '',
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        phone: user?.phone ?? '',
        dealerGroupNumber: user?.dealerGroupNumber ?? '',
      })
    } else {
      setRoles([DEFAULT_ROLE_ITEM])
    }
    if (user?.userRoleList) {
      setRoles(
        user?.userRoleList?.map((role) => ({
          applicationId: role.applicationId,
          userRoleId: role.userRoleId,
          isDefaultFlg: role.isDefaultFlg,
        })),
      )
    }
  }, [user])

  const reset = () => {
    formik.resetForm()
    onClose()

    if (!user) {
      setRoles([DEFAULT_ROLE_ITEM])
    }
  }

  const addOption = () => {
    if (roles.length >= applications.length) {
      return
    }

    setRoles([...roles, DEFAULT_ROLE_ITEM])
  }

  const removeRole = (idx: number) => {
    if (roles.length === 1) {
      return
    }

    setRoles([...roles.slice(0, idx), ...roles.slice(idx + 1)])
  }

  const getApplicationOptions = (roleApplicationId: number) => {
    const prevApplications = roles
      .filter((role) => role.applicationId !== -1 && role.applicationId !== roleApplicationId)
      .map((role) => role.applicationId)

    return applications.filter(
      (application) => !prevApplications.includes(Number(application.value)),
    )
  }

  const isValid = (): boolean => {
    const tRoles = roles.filter((role) => role.applicationId !== -1 && role.userRoleId !== -1)
    if (!tRoles.length) {
      return false
    }

    return tRoles.some((role) => role.isDefaultFlg)
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            User Details
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                id='firstName'
                name='firstName'
                value={formik.values.firstName}
                placeholder='First Name'
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextField
                fullWidth
                id='lastName'
                name='lastName'
                placeholder='Last Name'
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
              <TextField
                fullWidth
                id='email'
                name='email'
                placeholder='Email'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                fullWidth
                id='phone'
                name='phone'
                placeholder='Phone'
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <TextField
                fullWidth
                id='dealerGroupNumber'
                name='dealerGroupNumber'
                placeholder='Dealer Group Number'
                value={formik.values.dealerGroupNumber}
                onChange={formik.handleChange}
                error={formik.touched.dealerGroupNumber && Boolean(formik.errors.dealerGroupNumber)}
                helperText={formik.touched.dealerGroupNumber && formik.errors.dealerGroupNumber}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h6' component='h5'>
                  Applications
                </Typography>
                <IconButton onClick={addOption}>
                  <AddCircleIcon color='primary' />
                </IconButton>
              </Box>
              <Typography
                sx={styles.noMargin}
                variant='subtitle1'
                component='span'
                align='center'
                color='lightgrey'
              >
                Add applications with roles and select one as a default option.
              </Typography>
              {roles.map((role, idx) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }} key={`role-${idx}`}>
                  <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    placeholder='Application'
                    value={applications.find(
                      (application) => application.value === role.applicationId,
                    )}
                    // @ts-ignore
                    onChange={({ value }) => {
                      setRoles([
                        ...roles.slice(0, idx),
                        {
                          applicationId: value,
                          userRoleId: role.userRoleId,
                          isDefaultFlg: role.isDefaultFlg,
                        },
                        ...roles.slice(idx + 1),
                      ])
                    }}
                    // @ts-ignore
                    options={getApplicationOptions(role.applicationId)}
                    className='react-select-container'
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '56px',
                      }),
                    }}
                  />
                  <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    placeholder='User Role'
                    value={options.find((option) => option.value === role.userRoleId)}
                    // @ts-ignore
                    onChange={({ value }) => {
                      setRoles([
                        ...roles.slice(0, idx),
                        {
                          applicationId: role.applicationId,
                          userRoleId: value,
                          isDefaultFlg: role.isDefaultFlg,
                        },
                        ...roles.slice(idx + 1),
                      ])
                    }}
                    // @ts-ignore
                    options={options}
                    className='react-select-container'
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '56px',
                      }),
                    }}
                  />
                  <Tooltip title='Make default' arrow>
                    <Checkbox
                      checked={!!role.isDefaultFlg}
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={(e) => {
                        setRoles([
                          ...roles.slice(0, idx).map((role) => ({ ...role, isDefaultFlg: false })),
                          {
                            applicationId: role.applicationId,
                            userRoleId: role.userRoleId,
                            isDefaultFlg: e.target.checked,
                          },
                          ...roles.slice(idx + 1).map((role) => ({ ...role, isDefaultFlg: false })),
                        ])
                      }}
                    />
                  </Tooltip>
                  <Tooltip title='Delete' arrow>
                    <IconButton onClick={() => removeRole(idx)}>
                      <CancelIcon color='error' />
                    </IconButton>
                  </Tooltip>
                </Box>
              ))}
            </Stack>
            <Box sx={{ textAlign: 'right', mt: '30px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                color='error'
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                sx={{ borderRadius: '20px' }}
                size='large'
                type='submit'
                disabled={!isValid()}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
